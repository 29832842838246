<template>
  <!-- 选择认证方式 -->
  <el-dialog title="请选择认证方式"
    :visible.sync="dialogVisible"
    width="720px"
    :destroy-on-close="true"
    :lock-scroll="true"
    :append-to-body="true"
    @click="dialogVisible=false"
  >
    <div class="identification">
      <div class="box">
        <img :src="require('@/assets/imgs/workbench/ic_xiaozi.svg')" class="icon" />
        <div class="line-1">小紫认证-加盖公章授权认证</div>
        <div class="line-2">（审核时间：1-2个工作日）</div>
        <div class="line-3">需准备以下材料</div>
        <div class="line-box">
          <span class="point"></span>
          <span>营业执照照片</span>
        </div>
        <div class="line-box">
          <span class="point"></span>
          <span>管理员身份信息</span>
        </div>
        <div class="line-box">
          <span class="point"></span>
          <span>加盖公章的授权书</span>
          <span @click="downloadModel" class="download">下载授权书模版</span>
        </div>
        <div @click="toIdentify" class="button">去认证</div>
      </div>
      <div v-if="false" class="box">
        <img :src="require('@/assets/imgs/workbench/ic_dianziqian.svg')" class="icon" />
        <div class="line-1">电子签章-电子签章认证</div>
        <div class="line-2">（审核时间：1-2个工作日）</div>
        <div class="line-3">需准备以下材料</div>
        <div class="line-box">
          <span class="point"></span>
          <span>营业执照照片</span>
        </div>
        <div class="line-box">
          <span class="point"></span>
          <span>管理员身份信息</span>
        </div>
        <div class="line-box">
          <span class="point"></span>
          <span>加盖公章的授权书</span>
          <span class="download">下载授权书模版</span>
        </div>
        <div class="button" style="opacity: 0.5;">去认证</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  methods: {
    initPage () {
      this.dialogVisible = true;
    },
    toIdentify () {
      this.dialogVisible = false;
      this.$router.push({
        name: 'IDENTIFICATION',
      });
    },
    downloadModel () {
      const url = `${process.env.VUE_APP_HOST}10000000000/certificationLetter.pdf`;
      const filename = '认证公函模板';
      var xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(xhr.response);
        link.target = '_blank';
        link.download = filename;
        link.click();
        link.remove();
      };
      xhr.send();
    },
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.identification {
  display: flex;
  justify-content: space-around;
  padding-bottom: 50px;
  .box {
    width: 315px;
    padding: 12px 20px 20px;
    background: #F5F7FA;
    border-radius: 10px;
    .icon {
      width: 48px;
      height: 48px;
      margin-bottom: 5px;
    }
    .line-1 {
      font-size: 14px;
      font-weight: bold;
      color: #1F2733;
      margin-bottom: 5px;
    }
    .line-2 {
      font-size: 12px;
      font-weight: 400;
      color: #5F6A7A;
      margin-bottom: 20px;
    }
    .line-3 {
      font-size: 12px;
      font-weight: 400;
      color: #929AA6;
      margin-bottom: 10px;
    }
    .line-box {
      font-size: 14px;
      font-weight: 400;
      color: #1F2733;
      display: flex;
      align-items: center;
      .point {
        width: 6px;
        height: 6px;
        background: #237FFA;
        margin-right: 5px;
        border-radius: 50%;
      }
      .download {
        font-size: 12px;
        font-weight: 400;
        color: #237FFA;
        padding-left: 15px;
        cursor: pointer;
      }
    }
    .button {
      color: #ffffff;
      margin-top: 35px;
      text-align: center;
      padding: 6px;
      background: #237FFA;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
